/* @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"); */

/* Variables */
:root {
  --primary: #049DD7;
  --primaryDark: #182d44;
  --primaryLight: #f1fcfd;
  --primaryLinkColor: #049DD7;
  --lightText: #a3a3a3;
  --darkText: #3e3e3e;
  --blackText: #000000;
  --whiteBg: #ffffff;
  --bodyBg: #f9f9f9;
  --lightBorderColor: #e9e9e9;
  --darkBorderColor: #d1d1d1;
  --lightWarning: #8e7149;
  --darkWarning: #f8824f;
  --lightSkyBlue: #62bee6;
  --lightPurple: #9695c5;
  --colorA7D8ED: #a7d8ed;
  --colorF4E5B0: #f4e5b0;
  --colorC1F0DF: #c1f0df;
  --colorF6D5CD: #f6d5cd;
  --colorEBEBEB: #ebebeb;
  --colorF1F1F1: #f1f1f1;
  --colorB5CFE8: #b5cfe8;
  --color324B58: #324b58;
  --colorFF5353: #ff5353;
  --color9BB6D5: #9bb6d5;
  --colorFDA53D: #fda53d;
  --colorFF9330: #ff9330;
  --color727272: #727272;
  --color9A895D: #9a895d;
  --colorCA40D6: #ca40d6;
  --colorECCE62: #ecce62;
  --colorB8DEED: #b8deed;
  --colorEBBB13: #ebbb13;
}

body {
  overflow: scroll;
}


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,

video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  /* font: inherit; */
  font-family: "Work Sans", sans-serif !important;
  /* font-family: "Raleway", sans-serif !important; */
  /* font-family: "Poppins" !important; */
  /* font-family: "Plus Jakarta Sans", sans-serif; */
  /* font-weight: 500; */
  /* font-weight: 500 !important; */
  vertical-align: baseline;
  /* overflow-x: hidden; */
  position: relative;
}

p {
  margin-top: 0;
  margin-bottom: 0 !important;
}

textarea {
  font-size: 100%;
  font-family: "Work Sans", sans-serif !important;

}

.main-navigation {
  padding-left: 0px !important;
}

.MuiDataGrid-cell {
  align-items: center;
  display: flex;
}



#pointer {
  border: solid 10px transparent;
  border-bottom-color: #fff;
  position: absolute;
  top: -20px;
  left: 40%;
}

.housekeeping-button {
  width: 100px !important;
  color: #ffffff !important;
  padding: 5px 10px !important;
  margin: 5px 0px !important;
}

.housekeeper-assign-button {
  width: fit-content !important;
  min-width: 100% !important;
  color: #ffffff !important;
  padding: 5px 10px !important;
  margin: 5px 0px !important;
}

.housekeeping-button-room {
  width: 160px !important;
  color: #ffffff !important;
  padding: 5px 10px !important;
  margin: 5px 0px !important;
  word-wrap: break-word;
  word-break: break-all;
}

#HouseKeeping-file .filepond--drop-label {
  width: fit-content;
  background-color: #ffffff;

}

#HouseKeeping-file .filepond--drip {
  opacity: 1;
  background-color: #ffffff;
}

#HouseKeeping-file .filepond--panel-root {
  background-color: #ffffff;
}

#HouseKeeping-file .filepond--wrapper {
  width: auto;
  height: fit-content;
  min-width: 150px;
}

.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 60vh !important;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

#direct-gallery-image {
  max-height: 100vh !important;

}

.fullscreen #direct-gallery-image {
  height: auto !important;
  object-fit: contain !important;

}

.fullscreen .direct-gallery-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Default height when not in fullscreen */
}

.fullscreen .image-gallery-slide img {
  max-height: 80vh !important;
}

#inhouse-attach-button {
  padding: 5px;
  border: 2px solid #3498DB;
  border-radius: 20px;
  /* cursor: pointer; */
  z-index: 10;
  padding: 0px 15px;
}

.housekeep-clean-btn {
  padding: 5px;
  background-color: #3498DB !important;
  border-radius: 20px !important;
  cursor: pointer;
  color: #ffffff !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: 5px 15px !important;
  margin-bottom: 15px !important;
  /* position: absolute !important; */
  /* right: 10px !important; */
}

.filepond--credits {
  display: none;
}

.skiptranslate>iframe {
  visibility: hidden !important;
}

.goog-te-gadget-simple {
  white-space: nowrap;
  padding: 10px 5px;
  border-radius: 12px;
  border: 1px solid #D5D5D5;
}

#Montserrat {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
}

#Montserrat-black {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
  color: #000;

}

#Montserrat-600 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  align-items: center !important;
  caret-color: transparent;
}

.react-datepicker__input-container>input {
  border: 0px;
  padding: 11px 0px !important;
  font-family: "Work Sans", sans-serif !important;
  font-weight: 400;
  color: #384850;
  font-size: 1rem;
  width: 100%;
}

.react-datepicker__input-container>input:focus-visible {
  border: 0px;
  outline-width: 0px;
}

.directText {
  color: #384850 !important;
}

.pos-qty-button>.left-button {
  background-color: #E6E6E6;
  border: none;
  box-shadow: none;
  border-radius: 100%;
  color: #000;
}

.pos-qty-button>.left-button:disabled {
  background-color: #E6E6E6;
  border: none;
  box-shadow: none;
  border-radius: 100%;
  color: #000;
}

.pos-qty-button>.right-button {
  background-color: #049DD7;
  border: none;
  box-shadow: none;
  border-radius: 100%;
  color: #ffffff;
}

.pos-qty-button>.right-button:disabled {
  background-color: #049DD7;
  border: none;
  box-shadow: none;
  border-radius: 100%;
  color: #ffffff;
}

.pos-qty-button>.right-button:hover {
  background-color: #049DD7 !important;

}

.pos-qty {
  border: 1px solid #E6E6E6;
  border-radius: 35px;
  padding: 2px;
  width: fit-content;
  margin-top: 10px;
}

.direct-qty-button>.left-button {
  background-color: #384850;
  border: none;
  box-shadow: none;
  border-radius: 14px 0px 0px 14px;
  color: #ffffff;
}

.direct-qty-button>.right-button {
  background-color: #384850;
  border: none;
  box-shadow: none;
  border-radius: 0px 14px 14px 0px;
  color: #ffffff;
}

.direct-qty>.left-button {
  border-radius: 30px 0px 0px 30px !important;
  font-size: large;
}

.direct-qty>.right-button {
  border-radius: 0px 30px 30px 0px !important;
  font-size: large;

}


#addon-select-direct .addon-select-direct::before {
  border: 0px !important;
}

#addon-select-direct .addon-select-direct::after {
  border: 0px !important;
  background-color: #ffffff;
}

#addon-select-direct .MuiInput-underline {
  font-family: "Montserrat", sans-serif !important;
  /* color: #ABAFB1 !important; */
  font-size: 16px !important;
}

#addon-select-direct .MuiInput-underline::before {
  border: 0px !important;
}

#addon-select-direct .MuiInput-underline::after {
  border: 0px !important;
  background-color: #ffffff;
}

.textfield-direct {
  background-color: #ffffff;
  border: 1px solid #CFD3D4;
  border-radius: 5px;
}

#textfield-sub-direct-aggree {
  border: 0px !important;
}

.pos-button {
  color: #049DD7 !important;
  border: 2px solid #049DD7 !important;
  border-radius: 30px !important;
  font-size: 14px !important;
  padding: 2px 10px !important;
  font-weight: 500 !important;

}

.pos-comman-button {
  background-color: #049DD7 !important;
  color: #ffffff !important;
  border-radius: 30px !important;
  font-size: 14px !important;
  padding: 5px 10px !important;
  font-weight: 500 !important;

}

.room-detail-direct {
  color: #384850 !important;
  border: 2px solid #384850 !important;
  border-radius: 30px !important;
  font-size: 18px !important;
  padding: 5px 20px !important;
  font-weight: 500 !important;
}

.room-detail-next-btn {
  color: #ffffff !important;
  background-color: #384850 !important;
  border-radius: 30px !important;
  font-size: 18px !important;
  padding: 10px 20px !important;
  font-weight: 500 !important;
}

.room-detail-next-btn-dialog {
  color: #ffffff !important;
  background-color: #384850 !important;
  border-radius: 30px !important;
  font-size: 18px !important;
  padding: 10px 40px !important;
  font-weight: 500 !important;
}

.direct-confirm-pay-card {
  background: linear-gradient(90deg, #000000 0%, #434343 100%);
  height: 184px;
  max-width: 315px;
  border-radius: 16px;
}

.direct-confirm-pay-content {
  color: #FCFCFC;
  height: 80%;
  align-content: end;
}

a {
  text-decoration: none !important;
  transition: all 0.3s;
  color: #4087fc;
  font-weight: 700;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

::-webkit-scrollbar-thumb {
  border: 0 !important;
  background-color: #b2b2b2 !important;
  border-radius: 12px !important;
}

/* SideBar */

/* .sidebar {
    margin-left: -260px;
  } */
.show-sidebar .sidebar .main-content {
  margin-left: 0;
  padding: 0;
}

/* loader*/

/* overlay */
.reports {
  color: #3f51b5;
  cursor: pointer;
  /* border-bottom: 1px solid #3f51b5; */
  /* background-image: url(images.IcChecked); */
  font-size: 17px;
}

/* .filepond--root {
    max-height: 20em;
  } */

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
  }

  #print-report {
    min-width: 1150px !important;

  }


}

a {
  color: #049DD7 !important;
}

.jodit-status-bar-link {
  display: none !important;
}

#release_print,
#print_general_release {
  ul {
    /* list-style: inside; */
    margin-left: 1em;
    line-height: 180%;

  }

  /* // allows default ol to show up */
  ol {
    /* list-style: inside; */

    margin-left: 1em;
    line-height: 180%;


  }
}

#myjoditEditor,
#mySecondJoditEditor {

  /* // text can start 10 px within the editor */
  .jodit-wysiwyg {
    padding-left: 10px;
  }

  /* // allows default ul and dot to show up  */
  ul {
    /* list-style: inside; */
    margin-left: 1em;
    line-height: 180%;

  }

  /* // allows default ol to show up */
  ol {
    /* list-style: inside; */
    list-style-type: number;
    margin-left: 1em;
    line-height: 180%;


  }

  /* // allows table borders to show up */
  td {
    border: solid 1px;
  }
}

.comman-button:disabled {
  background-color: #777676 !important;
}

.comman-button {
  background-color: #049DD7 !important;
  border-radius: 20px !important;
  padding: 8px 16px !important;
  color: #ffffff !important;
  font-family: "Plus Jakarta Sans";
  font-size: 14px !important;
  font-weight: bold !important;
  height: fit-content;
}

.create-comman-button {
  background-color: #049DD7 !important;
  border-radius: 20px !important;
  padding: 18px 36px !important;
  color: #ffffff !important;
  font-family: "Plus Jakarta Sans";
  font-size: 18px !important;
  font-weight: bold !important;
  height: fit-content;
}

.comman-cancel-button {
  background-color: #777676 !important;
  border-radius: 20px !important;
  padding: 8px 16px !important;
  color: #ffffff !important;
  font-family: "Plus Jakarta Sans";
  font-size: 14px !important;
  font-weight: bold !important;
  height: fit-content;
}

.passange-verify-header-box {
  /* background-color:#eb1834; */
  height: fit-content;
  width: 100%;
  /* border-bottom: 1px solid black; */
}


/* .MuiDataGrid-virtualScrollerContent{
  overflow-x: scroll !important;
  }
  
  .css-1wyhbhi-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
    min-width: 100px !important;
  }
  
  
  
  .MuiDataGrid-columnHeader{
    min-width: 100px !important;
  } */

.fc-toolbar-title {
  font-weight: 400 !important;
}

.fc .fc-highlight {
  background: #7e83b9;
  border-radius: 5px;

}

.reports:hover {
  color: #049DD7;
  border-bottom: 1px solid #049DD7;
  font-size: 18px;
}

.sigCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 350px;
  /* left: 25px; */
}

.sigCanvass {
  border: 1px solid black;
  width: 100%;
  max-height: 300px;
  /* left: 25px; */
  background-color: #FFFFFF;
  border: 0px solid #CFD3D4;
  border-radius: 8px;
}

.MuiCircularProgress-svg {
  color: #049DD7 !important;
}

.loader-wrapper {
  /* position: fixed; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  /* background: rgba(0, 0, 0, 0.4); */
  background: rgba(252, 252, 252, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-wrapper-main {
  position: fixed;
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  /* background: rgba(0, 0, 0, 0.4); */
  background: rgba(252, 252, 252, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-loader-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba(219, 218, 218, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* tiny */
.tox-statusbar__text-container,
.tox-statusbar {
  visibility: hidden;
}

/* fc-timeline-slot fc-timeline-slot-label fc-day fc-day-thu  */
/* class=" fc-resource" */

.fc-day-today {
  background-color: #d8e7ff;
  /* opacity: 20%; */
}

.fc-day-today>div {
  background-color: #049DD7;

  color: white
}


#plus-jakarta {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-weight: 500 !important;
}

#plus-jakarta-font {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

#work-sans-font {
  font-family: "Work Sans", sans-serif !important;
}

.fc-day-today>div>a {
  color: white;
}

/* Full Calender */

#BookingContainer {
  width: 90%;
  border-right: 1px solid;
}

#BookinButtonContainer {
  bottom: 35%;
  width: fit-content;
  left: 91%;
}

/* Login */
.login-wrapper {
  height: 100vh;
  background: linear-gradient(140deg, var(--primary) 40%, #fff 0);
  background-size: 100%;
  display: flex;
  justify-content: space-between;
}

.login-static-wrapper {
  margin-top: 10vh;
  margin-left: 5vw;
  float: left;
}

.login-static-wrapper #welcome-image {
  width: 30vw;
  height: auto;
}

.login-static-wrapper p {
  /* color: var(--whiteBg); */
}

.login-static-wrapper #login-welcome-back {
  font-size: 1.5rem;
  font-weight: 600;
}

.main-content {
  transition: all 0.3s ease-in-out;
  padding-top: 62px !important;
}

.main-layout-background {
  background-color: #ffffff;
  min-height: 100vh !important;
  height: auto !important;

}

.page-with-box,
.page-with-box-hotel-details {
  max-width: 510px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

#font-400 {
  font-weight: 400 !important;
}

#font-500 {
  font-weight: 500 !important;
}

#font-600 {
  font-weight: 600 !important;
}

#font-700 {
  font-weight: 700 !important;
}

#font-bold {
  font-weight: bold !important;
  font-family: "Plus Jakarta Sans" !important;
  font-size: 14px !important;
}

#mb-1 {
  margin-bottom: 10px !important;
}

#flash-head {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Kitchen Dashboard */

.kitchen-box-container {
  padding: 16px;
  /* color: #fff; */
  background-color: #049DD7;
  color: #ffffff !important;

  height: 100%;
}

.kitchen-box-right-head {
  font-size: smaller;
  text-align: right;
}

#pinterest-container {
  -webkit-column-count: 3;
  -webkit-column-gap: 20px;
  -moz-column-count: 3;
  -moz-column-gap: 20px;
  column-count: 3;
  column-gap: 20px;
  grid-auto-flow: row dense;
}

#pinterst-box {
  width: 100% !important;
  height: auto !important;
  padding-top: 20px;
}


.row-active {
  background-color: #e6f7ff;
  /* Light blue for active rows */
}

.direct-cal-active-date {
  background-color: #059CD5;
  border-radius: 100%;
  color: #ffffff;
  font-size: 10px;
  cursor: pointer;
}

.direct-cal-date {
  border-radius: 100%;
  font-size: 10px;
  cursor: pointer;
}

/* //calender resourcess */

.fc-datagrid-cell-cushion {
  text-align: left !important;
  font-weight: 500 !important;
}

.fc .fc-datagrid-header .fc-datagrid-cell-frame {
  display: flex !important;
  justify-content: center !important;
  width: 100%;
  background-color: #049DD7 !important;
  color: white;

}

.fc-resource .fc-datagrid-cell-frame {
  background-color: rgb(230, 229, 229) !important;
}


.fc-datagrid-cell-frame-liquid {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: rgb(230, 229, 229) !important;
  text-align: center !important;
}

.fc-resource .fc-datagrid-cell-frame {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
}

.fc .fc-datagrid-cell-cushion {
  text-align: center !important;

}

#align-head-left {
  text-align: left;
  font-weight: 600;
}

/* .report-header{
    text-align: left;
    font-weight: 600 !important;
  } */

.fc-direction-ltr {
  text-align: center !important;
}

.fc-datagrid-cell-main {
  font-weight: 500 !important;
  text-wrap: wrap;
}


.fc-theme-standard td {
  border: 1px solid rgb(109, 109, 109) !important;
}

.fc-scrollgrid-section-sticky {
  z-index: 1100;
  top: 77px;
  position: sticky;
}

td+.fc-resource-timeline-divider {
  border: 0px !important;
  width: 0px !important;
  background-color: #E6E5E5 !important;
}


.fc-scrollgrid-section-sticky:first-child>td:first-child {
  border-right: 0px !important;
  background: border-box !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #000;
}

.fc-scrollgrid-section-body:first-child>td:first-child {
  border-right: 0px solid #E6E5E5 !important;
  background: border-box;
}

.fc-resource-timeline-divider {
  border: 0px !important;
  background: border-box !important;
  background-color: #049DD7 !important;
  color: #ffffff !important;

}

.fc .fc-button-primary {
  background-color: #049DD7 !important;
  color: #ffffff !important;
  /* color: #000 !important; */
  border-radius: 24px;
  border: 0px;
  font-weight: 600;
  padding: 8px 16px;
}

/* calender events */

.sell-option {

  padding-left: 10px;
  padding-right: 10px;
  word-break: break-all;

}

#green-back {
  background-color: #049DD7 !important;
  /* color: #000 !important; */
  color: #ffffff !important;

}

.cale-event {
  height: 100%;
  margin-bottom: -20px;
}

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltiptext {
  margin-top: -34px !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: rgb(80, 78, 78);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 1px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 20000;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.fc-scrollgrid div {
  font-weight: 500;
}

.fc .fc-timeline-slot-frame {
  justify-content: center !important;
  background-color: #049DD7;
  color: white;

  .fc-timeline-slot-cushion {
    /* color: rgb(0, 0, 0) !important; */
    color: #ffffff !important;


  }
}

.fc-day-today>div {
  background-color: #008000 !important;
}

.jodit-wysiwyg p,
.jodit-context p {
  margin: 13px 0px;
}

.jodit-context-current p {
  margin: 13px 0px !important;
}


/* multi select */

.multi-select-height>.MuiSelect-select {
  height: fit-content !important;
}

/* hotel details */
.slick-slide {
  display: flex !important;
  justify-content: center;
}

.page-with-box-hotel-details {
  max-width: 585px;
}

.padding-bottom {
  padding-bottom: 16px;
}

.button__bar {
  position: relative;
  top: -535px;
  margin: 0 auto;
  display: flex !important;
  max-width: 60px;
  justify-content: space-between;
  height: 30px;
  position: relative;
}

.button__bar li button {
  border: none;
  background: white;
  color: transparent;
  cursor: pointer;
  display: block;
  border: 1px solid black;
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
}

.button__bar li.slick-active button {
  background-color: #049DD7;
  color: #ffffff !important;

}

.location-search-input {
  box-sizing: border-box;
}


.bg-danger {
  background-color: red;
}

.d-flex {
  display: flex;
}

.d-hidden {
  overflow: hidden I !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-97 {
  width: 97% !important;
}

.ws-100 {
  width: calc(100% - 32px) !important;
  /* padding: 0 16px; */
}

.w-100 {
  width: 100% !important;
}

.w-25p {
  width: 25px !important;
}

.w-75p {
  width: 75px !important;
}

.h-20 {
  height: 20% !important;
}

.h-25p {
  height: 25px !important;
}

.h-35p {
  height: 35px !important;
}

.h-30 {
  height: 30% !important;
}

.h-40 {
  height: 40% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-61p {
  height: 61px !important;
}

.h-70 {
  height: 70% !important;
}

.h-100 {
  height: 100% !important;
}

.h-100vh {
  height: 100vh !important;
}

.h-130vh {
  height: 130vh !important;
}

.h-auto {
  height: auto !important;
}

.text-12 {
  font-size: 12px !important;
}

.text-14 {
  font-size: 14px !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-18 {
  font-size: 18px !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.ml-auto {
  margin: auto;
}

.mt-auto {
  margin-top: auto;
}

.mt-2rem {
  margin-top: 2rem;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-22 {
  margin-top: 22px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.border-0 {
  border: none;
}

#text-bold {

  font-weight: 700 !important;
}

.border-bottom-0 {
  border-bottom: 0px solid black !important;
}

.form-field {
  width: 230px !important;
}

.css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
  height: 48px !important;
  margin-top: 7px;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
}

.MuiFormLabel-filled {
  font-weight: 600 !important;
  color: black !important;
}

.pagename-heading {
  font-size: 26px !important;
  font-weight: bold !important;
  margin-left: 2.5rem;
  margin-right: 1rem;
}

#print_general_release {
  font-size: 12px !important;
}

.tab-heading {
  font-size: 22px;
  font-weight: bold !important;
  margin-bottom: 2.5rem;
}

.newBoxBefore2 {
  break-before: page;
}

#chatCon {
  /* display: flex; */
  /* justify-content: end; */
  z-index: 1500;

  position: fixed !important;
  bottom: 2%;
  right: 3%;
}

#back-gray {
  background-color: #808080 !important;
}

#color-black {
  color: #000;
}

.chat-agreement {
  position: absolute;
  right: 0;
  top: -40px;
  margin-right: 10px;
  font-size: xx-large !important;
  cursor: pointer;
}

.justify-content-center {
  justify-content: center !important;
}


/* custom datagrid */

.tableColum-datagrid {
  padding: 10px;
  border-bottom: 1px solid #E0E0E0;
  min-width: 70px;
  text-align: left;
}

.dashboard-container {
  background-color: #ffffff;
  padding: 37px 38px 0 38px;
  /* height: 100vh; */
}

.dashboard-tiles {
  height: 60%;
  background-color: #F5F5F5;
  border-radius: 12px;
  display: flex;
  /* flex-direction: column; */
  margin-bottom: 8px;
  padding: 24px;
  align-items: center;
  /* box-shadow: 5px 5px 5px #80808061; */
  margin-right: 7px;
}

.owner-dashboard-tiles {
  height: 60%;
  border: 2px solid #F5F5F5;
  border-radius: 12px;
  display: flex;
  /* flex-direction: column; */
  margin-bottom: 8px;
  padding: 24px;
  align-items: center;
  /* box-shadow: 5px 5px 5px #80808061; */
  margin-right: 7px;
}

.border-below {
  border-bottom: 1px solid #F5F5F5;
}



.dashboard-tiles img {
  width: 30px;
  height: 30px;
  margin-top: auto;
  margin-bottom: auto;
}

.dashboard-calendar {
  /* height: 550px !important; */
  background-color: #F5F5F5;
  border-radius: 12px !important;
  /* box-shadow: 5px 5px 5px #80808063; */
  margin-bottom: 10px;
  /* overflow: scroll; */
}

.calendar-container {
  padding: 27px 27px 27px 41px;
}

.freebus-input {
  width:100%;
  border-radius: 5px !important ;
  padding: 10px ;
  border: 1px solid #CCCCCC !important;
  box-shadow: 0px 2px 4px 0px #00000026 !important;
  outline: none ;
}

.dashboard-graph {
  /* height: 550px !important; */
  background-color: #F5F5F5;
  border-radius: 12px !important;
  /* box-shadow: 5px 5px 5px #80808063; */
  overflow: hidden;
  margin-bottom: 10px;
  /* overflow: auto; */
}

.dashboard-graph-owner {
  /* height: 550px !important; */
  border: 1px solid #F5F5F5;
  border-radius: 12px !important;
  /* box-shadow: 5px 5px 5px #80808063; */
  overflow: hidden;
  margin-bottom: 10px;
  /* overflow: auto; */
}

.dashboard-information {
  width: 100%;
  height: 124px;
  border-radius: 8px;
  background: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(45, 47, 50, 0.2);
  margin-bottom: 10px;
}

.apexcharts-canvas .apexchartsbasic-bar .apexcharts-theme-light {
  overflow: hidden !important;
}

.apexcharts-canvas {
  overflow: hidden;
}

/* Calendar */
.react-calendar {
  width: 100% !important;
  border: none !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid #e5e5e5 !important;
  background-color: #F5F5F5 !important;
}

.react-calendar__navigation {
  max-width: 190px;
  margin-left: auto;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  position: absolute;
  visibility: hidden;
}

.react-calendar__navigation button {
  min-width: 0px !important;
  font-size: 30px;
  color: #7d8da6 !important;
}

.react-calendar__navigation__arrow {
  margin-top: -5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7d8da6 !important;
  font-size: 16px;
  line-height: 24px;
}

.react-calendar__navigation__label__labelText {
  color: #7d8da6 !important;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
}

.react-calendar__navigation__arrow:focus {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
  border-radius: 8px;
}


.react-calendar__navigation__arrow:hover,
.react-calendar__navigation__label__labelText:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  font-weight: 700 !important;
  font-size: 12px;
  line-height: 19px;
  color: #141736;
}

.react-calendar__tile abbr {
  font-weight: 700 !important;
  font-size: 12px;
  line-height: 19px;
  text-align: right;
  color: #141736;
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: #7d8da6 !important;
}

.react-calendar__tile--now {
  padding: 3px 0px !important;
  border-radius: 8px;
  margin-top: 7px !important;
  width: 39px;
  height: 24px;
  background: yellow !important;
}

.react-calendar__tile--active {
  width: 39px;
  height: 24px;
  background: #049DD7 !important;
  color: #ffffff !important;

  padding: 3px 0px !important;
  border-radius: 8px;
  margin-top: 7px !important;


}

.react-calendar__tile--active>abbr {
  color: #ffffff !important;

}

.react-calendar button:hover {
  /* padding: 0px 10px 0px 13px !important; */
  border-radius: 8px;
  /* margin-top: 7px !important; */
  /* width: 39px;
    height: 24px; */
}

/* .react-calendar__tile--active abbr {
    color: #ffffff !important;
  } */

.filepond--root {
  /* width:0; */
  /* max-height: 10em; */
}

.filepond--item-panel {
  border-radius: 0.5em;
}

.filepond--item {
  width: calc(25% - .5em);
  /* max-height: 10em; */
}

.tab-block {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: #ffffff;
}

.tab-block .MuiTabs-indicator {
  height: 6px;
}

.tab-block .MuiTabs-flexContainer {
  justify-content: space-between;
}

.nav-header {
  border-bottom: 1px solid gray;
  display: flex;
  color: gray;
  font-size: 20px;
  margin-top: 2rem;
  width: 100%;
  /* height: 50px; */
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  /* padding-left: 20px; */
  justify-content: flex-start;
  gap: 10px;
  /* padding-bottom: 11px; */
}

/* Styling the scrollbar */
.nav-header::-webkit-scrollbar {
  width: 5px !important;
  /* Set the width of the scrollbar */
  height: 5px !important;
}

.nav-header::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the scrollbar thumb */
  width: 4px !important;
  height: 2px !important;
}

.nav-header::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Set the color of the scrollbar track */
}

.nav-tabs {
  width: fit-content;
  font-weight: 500 !important;
  margin-top: 6px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.nav-tab {
  width: auto;
  padding-left: 22px;
  padding-right: 22px;
  font-weight: 500 !important;
  margin-top: 6px;
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.roomRateTabHeader .nav-tab {
  padding-left: 15px;
  padding-right: 15px;
}

.active {
  border-left: 7px solid #049DD7;
  color: #ffffff !important;

  margin-left: 15px;
}

.active-tab {
  border-bottom: 6px Solid #049DD7;
  /* color: #ffffff !important; */

}

.sidebar-icon {
  width: 25px;
  height: 25px;
  margin-left: 30px;
  margin-right: 20px;
}

thead th {
  padding-right: 50px;

}

#printable-content {
  width: 932px !important;

}

/* .MuiDataGrid-cell, .MuiDataGrid-columnHeader{
      width: 150px !important;
    } */


/* @media (min-width: 600px) { */


.secure-container {
  gap: 80px;
}

.secure-logo {
  width: fit-content;
  display: flex;
  align-self: center;
}

.sofa-text {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute !important;
  cursor: pointer;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 100%;
}

@media (max-width: 470px) {
  .secure-container {
    flex-direction: column;
    gap: 20px;
  }

  .direct-header {
    flex-direction: column-reverse;
  }

  .direct-header>div:first-child {
    padding-top: 5px !important;
  }

  .direct-header-sub {
    padding-bottom: 5px !important;
    /* padding-top: 15px !important; */

  }
}


@media (max-width: 600px) {
  .image-direct-container {
    flex-direction: column;
  }

  .css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
    width: 80px !important;
  }

  #BookingContainer {
    width: 100%;
    border-right: 0px;
  }

  #BookinButtonContainer {
    bottom: -35px;
    width: 100%;
    left: 47%;
    margin-top: 10px;
  }

  /* .form-field {
      width: 130px !important;
    } */
}


.pl-xs-2 {
  padding-left: 20px;
}

/* @media (max-width:6) */

@media (max-width: 830.98px) {
  .main-content {
    padding: 0 0 0 260px !important;

  }

  .show-sidebar .main-content {
    padding: 62px 0 0 0px !important;
  }

  .about_privacy {
    padding: 10px !important;
    /* max-width: 400px !important;
          min-width: 400px !important; */
  }

  .pos-container {
    margin-top: 98px;
  }

}

@media (max-width: 830.98px) {}

@media (max-width: 599.98px) {
  .pos-container {
    margin-top: 25px;
  }

  .main-content {
    padding: 0px !important;
    padding-top: 62px !important;

  }

  .about_privacy {
    padding: 10px !important;
    max-width: 300px !important;
    min-width: 300px !important;
  }

  .dashboard-container {
    padding: 38px 30px 0 38px;
    transition: all 0.3s;
  }

  .margin-main {
    margin-top: 77px !important;
  }

  .show-sidebar .main-content {
    padding: 150px 5px 5px 5px !important;

  }

  .direct-container {
    margin-top: 200px;
  }

}

@media (min-width: 900px) {
  .main-content {
    padding: 0px 0px 20px 260px;

  }


  .sidebar {
    margin-left: 0;
    padding-top: 0;
  }

  .header {
    padding: "0px";
  }

  .show-sidebar .sidebar {
    margin-left: -260px;
  }

  .show-sidebar .main-content {
    padding: 0 0 0 0px;

  }

  .pos-total {
    padding-left: 300px;
  }
}


@media (max-width: 899px) {

  .pos-total {
    padding-left: 20px;

  }

  .flex-sm-row-reverse {
    flex-direction: column-reverse !important;
  }

  .main-content {
    padding: 0px 0px 20px 260px;

  }


  .sidebar {
    margin-left: 0;
    padding-top: 0;
  }

  .header {
    padding: "0px";
  }

  .show-sidebar .sidebar {
    margin-left: -260px;
  }

  .show-sidebar .main-content {
    padding: 0 0 0 0px;
  }

  #pinterest-container {
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
    grid-auto-flow: row dense;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (min-width: 900px) and (max-width:1199px) {
  .about_privacy {
    margin-top: -200px !important;
  }
}

.about_privacy {
  display: flex;
  width: 80%;
  margin: 0px auto 0px auto;
  justify-content: center;
  align-items: center;
  height: fit-content;
}


@media (min-width: 1200px) {

  .about_privacy_margin {}

  .main-content {
    padding: 0 0 0 260px;
    padding-top: 62px !important;

  }

  .show-sidebar .main-content {
    padding: 0 0 0 0px;
  }
}

.staps {
  flex-wrap: wrap !important;
  row-gap: 20px !important;
  gap: 50px;
}

.INWITNESSWHEREOF {
  margin-left: 150px;
  font-size: 13px;
  line-height: 200%;
}

.agreement_clear_button {
  justify-content: center;
  width: 100px;
  height: 35px;
  /* left: 45%; */
  text-transform: capitalize;
  background: linear-gradient(180deg, #255480 0%, #173450 100%);
}

.document {
  top: 20px;
}

.non-clickable-event {
  pointer-events: none;
  /* Disable pointer events for the non-clickable events */
  opacity: 0.6;
  /* Optionally, you can make them visually distinct, e.g., reduce opacity */
}

.stripe-1 {
  color: white;
  background: repeating-linear-gradient(45deg,
      #ff0000,
      #ff0000 10px,
      #049DD7 10px,
      #049DD7 20px);
}

.checkin-container {
  background-color: #ffffff;
  width: 70%;
  min-height: 500px;
  border-color: #000;
  margin: auto;
}


.checkboox_countent {
  /* left: 40px; */
  /* top: -35px; */
  font-size: 15px;
}

@media (max-width: 575px) {
  .staps {
    gap: 50px;
  }

  .about_privacy {
    margin-top: -200px;
  }

  #welcome-image {
    top: 70px;
  }

  .css-j5w0w9-MuiStepConnector-root {
    display: none;
  }

  .sigCanvas {
    left: 0;
  }

  .document {
    top: 10px;
  }

  .INWITNESSWHEREOF {
    margin-left: 0;
  }

  .agreement_clear_button {
    left: 0;
  }
}